import { NavSiteSection } from 'designsystem';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ArticleDetailQuery, ContentPagesQuery, GlobalsQuery } from '../gql/cms';
import { featureFlags, PillarNavigation, useCart, useIsSigningIn } from 'shared';
import { useRouter } from 'next/router';
import siteHandleByLocale from '../utils/siteHandleByLocale';

type PageQuery = ContentPagesQuery | ArticleDetailQuery;

const getLocalizedUri = (localized: PageQuery['entry']['localized'] | undefined, translationLocale: string) =>
    localized?.find(localizedPage => localizedPage.siteHandle === siteHandleByLocale[translationLocale])?.uri;

const FestivalNavigation: FC<{ globals: GlobalsQuery | null; content: PageQuery; isLoading: boolean }> = ({
    globals,
    content,
    isLoading,
}) => {
    const { topLevelPages, childPages } = globals ?? {};
    const { locale } = useRouter();
    const { onOpenCart } = useCart();

    const pages: NavSiteSection[] = useMemo(
        () =>
            topLevelPages?.map(parentPage => ({
                key: parentPage.id,
                href: `/${parentPage.uri}`,
                title: parentPage.title,
                subPages: childPages
                    .filter(page => page.parent?.id === parentPage.id)
                    .map(page => ({
                        key: page.id,
                        href: `/${page.uri}`,
                        title: page.title,
                    })),
            })) ?? [],
        [topLevelPages, childPages]
    );

    const translationLocale = locale === 'en' ? 'nl' : 'en';
    const langSwitchUri = getLocalizedUri(content?.entry?.localized, translationLocale);

    const { formatMessage } = useIntl();
    const isLoggingIn = useIsSigningIn();

    return (
        <PillarNavigation
            pillarKey="festival"
            pages={pages}
            searchBarPlaceholder={formatMessage({
                defaultMessage: 'Zoeken',
            })}
            overviewLabel={formatMessage({
                defaultMessage: 'Terug naar overzicht',
            })}
            langSwitchUri={langSwitchUri}
            handleCartClick={onOpenCart}
            isCartLoading={isLoggingIn}
            festivalDate="13-23 nov 2025"
            myIdfaPath={featureFlags.festivalMyIDFAEnabled ? `/${locale}/myidfa` : undefined}
            isLoading={isLoading}
        />
    );
};

export default FestivalNavigation;
